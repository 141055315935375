import React from "react"
import ProjectLayout from "../../layouts/project"


export default () => {
    const projectId = 'the_humble_badger_podcast';

    return (
        <ProjectLayout>
            <React.Fragment>
                {projectId}
            </React.Fragment>
        </ProjectLayout>
    )
}

